<template>
  <div class="promoRecord">
    <Header title="推广记录" />
    <div class="main">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="promoItem" v-for="i in list" :key="i.id">
          <div class="userThumb">
            <ImgDecypt class="userThumbImg" round :src="i.portrait" :key="i.portrait" />
          </div>
          <div class="userName">{{ i.name || '用户名称' }}</div>
          <div class="registerDate">注册时间: {{ i.createAt | time }}</div>
        </div>
      </PullRefresh>
    </div>
  </div>
</template>

<script>
import { promotionRecord } from '@/api/mine';
import Header from '@/components/Header';
import PullRefresh from '@/components/PullRefresh';
import ImgDecypt from '@/components/ImgDecypt';
import { getSessionItem } from '@/utils/longStorage';
export default {
  name: 'PromoRecord',
  components: {
    PullRefresh,
    Header,
    ImgDecypt,
  },
  data() {
    return {
      list: [], // 列表
      pageNumber: 1, // 页码
      pageSize: 20, // 条数
      totalNum: 0, // 总条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
    };
  },
  created() {
    this.queryRecord();
  },
  methods: {
    async queryRecord(type) {
      let req = {
        appId: 0,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        userId: JSON.parse(getSessionItem('userInfo')).uid,
      };
      try {
        let res = await this.$Api(promotionRecord, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          this.totalNum = res.data.total;
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.queryRecord();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.queryRecord('refresh');
    },
  },
};
</script>

<style lang="scss" scoped>
.promoRecord {
  height: 100%;
  .main {
    height: calc(100vh - 50px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 20px;
    .promoItem {
      padding: 11px 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      border-bottom: 1px solid rgb(237, 237, 237);
      margin: 8px 0;
      border-radius: 4px;
      .userThumb {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        background-color: rgb(121, 121, 121);
        img {
          border-radius: 50%;
          overflow: hidden;
          width: 100%;
          height: 100%;
        }
      }
      .userName {
        flex: 2;
        margin: 0 9px;
        font-size: 14px;
        color: rgb(121, 121, 121);
      }

      .registerDate {
        font-size: 12px;
        color: rgb(121, 121, 121);
      }
    }
  }
}
</style>
